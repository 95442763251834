@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  /*height: max-content;*/
  /*min-height: 100vh;*/
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.test-indicator {
  position: fixed;
  bottom: 0;
  background-color: rgb(241, 92, 6);
  color: white;
  font-weight: bold;
  padding: 0.5em;
  text-align: center;
  z-index: 99999;
  right: 0;
  left: 0;
}

.mb-auto { margin-bottom:auto !important; }
.mb-0 { margin-bottom:0 !important; }
.mb-1 { margin-bottom:0.25em !important; }
.mb-2 { margin-bottom:0.5em !important; }
.mb-3 { margin-bottom:1em !important; }
.mb-4 { margin-bottom:1.5em !important; }
.mb-5 { margin-bottom:3em !important; }

.mt-auto { margin-top:auto !important; }
.mt-0 { margin-top:0 !important; }
.mt-1 { margin-top:0.25em !important; }
.mt-2 { margin-top:0.5em !important; }
.mt-3 { margin-top:1em !important; }
.mt-4 { margin-top:1.5em !important; }
.mt-5 { margin-top:3em !important; }

.ml-auto { margin-left:auto !important; }
.ml-0 { margin-left:0 !important; }
.ml-1 { margin-left:0.25em !important; }
.ml-2 { margin-left:0.5em !important; }
.ml-3 { margin-left:1em !important; }
.ml-4 { margin-left:1.5em !important; }
.ml-5 { margin-left:3em !important; }

.mr-auto { margin-right:auto !important; }
.mr-0 { margin-right:0 !important; }
.mr-1 { margin-right:0.25em !important; }
.mr-2 { margin-right:0.5em !important; }
.mr-3 { margin-right:1em !important; }
.mr-4 { margin-right:1.5em !important; }
.mr-5 { margin-right:3em !important; }

.p-0 { padding:0 !important; }
.p-1 { padding:0.25em !important; }
.p-2 { padding:0.5em !important; }
.p-3 { padding:1em !important; }
.p-4 { padding:1.5em !important; }
.p-5 { padding:3em !important; }

.pb-auto { padding-bottom:auto !important; }
.pb-0 { padding-bottom:0 !important; }
.pb-1 { padding-bottom:0.25em !important; }
.pb-2 { padding-bottom:0.5em !important; }
.pb-3 { padding-bottom:1em !important; }
.pb-4 { padding-bottom:1.5em !important; }
.pb-5 { padding-bottom:3em !important; }

.pt-auto { padding-top:auto !important; }
.pt-0 { padding-top:0 !important; }
.pt-1 { padding-top:0.25em !important; }
.pt-2 { padding-top:0.5em !important; }
.pt-3 { padding-top:1em !important; }
.pt-4 { padding-top:1.5em !important; }
.pt-5 { padding-top:3em !important; }

.pl-auto { padding-left:auto !important; }
.pl-0 { padding-left:0 !important; }
.pl-1 { padding-left:0.25em !important; }
.pl-2 { padding-left:0.5em !important; }
.pl-3 { padding-left:1em !important; }
.pl-4 { padding-left:1.5em !important; }
.pl-5 { padding-left:3em !important; }

.pr-auto { padding-right:auto !important; }
.pr-0 { padding-right:0 !important; }
.pr-1 { padding-right:0.25em !important; }
.pr-2 { padding-right:0.5em !important; }
.pr-3 { padding-right:1em !important; }
.pr-4 { padding-right:1.5em !important; }
.pr-5 { padding-right:3em !important; }

.d-flex { display:flex !important; }
.d-flex-end { display:flex !important; justify-content:end !important; }
.d-flex-jbetween { display:flex !important; justify-content:space-between !important; }
.d-flex-jcenter { display:flex !important; justify-content:center !important; }

.gap-1 { gap:0.2em !important; }
.gap-2 { gap:0.4em !important; }
.gap-3 { gap:0.6em !important; }
.gap-4 { gap:0.8em !important; }
.gap-5 { gap:1.0em !important; }

.c-pointer { cursor: pointer !important; }

::-webkit-scrollbar {
  width:  0px;
}

/*Track*/
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/*Handle*/
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover*/
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leaflet-container{
  width: 100%;
  height:100%;
}

a:link { text-decoration:none; } a:visited { text-decoration:none; }